import React, {FC, useEffect, useRef, useState} from "react";
import cls from "./builder.module.scss"
import {TranslationsType} from "../../types/homePageTypes";
import {builderDataType} from "./types";
import RenderBuilderComponent from "./RenderCustomElements";
import {ICurrencies} from "../ForCWV/Wrappers/MainLayout";

interface IProps {
    getBuilderData: Record<number, Array<builderDataType>>
    tab: number
    domain: string;
    dbName: string
    translationsData: TranslationsType,
    selectedLocale: string,
    currencies: Array<ICurrencies>;
    selectedCurrItems: {
        selectedCurrCode: string;
        selectedCurrRate: string;
        selectedCurrSymbol: string
    };
    isMobile: boolean,
    recaptchaActive: string
    backOrderValue: string
}

const BuilderSection: FC<IProps> = ({getBuilderData: clientShowMap, tab, ...builderComponentProps}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [originalBuilderSections, setOriginalBuilderSections] = useState<boolean>(false);
    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current) {
                setOriginalBuilderSections(window.scrollY >= 1)
            }
        }
        if (!originalBuilderSections && builderComponentProps.isMobile) {
            window.addEventListener("scroll", handleScroll)
        } else {
            window.removeEventListener("scroll", handleScroll)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    const BuilderJSX = ({dataKey}: any) => <RenderBuilderComponent data={dataKey} {...builderComponentProps}/>
    return (
        <div className="block-products">
            <div ref={!originalBuilderSections ? ref : null} className={`${cls["home-product-container"]} ${cls["b-container"]}`}>
                {(!originalBuilderSections && builderComponentProps.isMobile
                    ? {"1": [clientShowMap[1][0]], "2": [], "3": [], "4": []}
                    : clientShowMap)[tab]?.map((
                    {
                        idArray,
                        parentCls,
                        container,
                        rowStyles,
                        childWrapper,
                        parentAlignItems,
                        showOneComponent
                    } = {} as builderDataType, ind: number) => {
                    const handleComponentShow = {
                        "true": <BuilderJSX dataKey={showOneComponent}/>,
                        "false": idArray?.map((
                            {
                                childIdArray,
                                showOneComponent: showOneComponentChild,
                                childCls,
                                secondChildCls,
                                columnStyles
                            }, index: number) => {
                            const handleComponentShowChild = {
                                "true": <BuilderJSX dataKey={showOneComponentChild}/>,
                                "false": childIdArray?.map((elInEl, indElInEl: number) => (
                                    <div key={indElInEl} className={cls.builder_component}>
                                        <BuilderJSX dataKey={elInEl}/>
                                    </div>
                                ))
                            }
                            return <div key={index} className={`${childCls} ${cls[secondChildCls]}`}
                                        style={columnStyles}>
                                {handleComponentShowChild[`${!!showOneComponentChild}`]}
                            </div>
                        })
                    }

                    return <div key={ind} className={`${container} ${cls.builder_component}`}>
                        <div className={parentCls} style={rowStyles}>
                            <div style={{...childWrapper, alignItems: parentAlignItems, width: "100%"}}>
                                {handleComponentShow[`${!!showOneComponent}`]}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default BuilderSection
